<script>
    import { setContext, onMount } from 'svelte';
    import BidView from '../../elementsJs/referralClaim/BidView.svelte';
    import UserAuctionCard from './UserAuctionCard.svelte';
    import MarketplaceNav from '../../Pages/marketPlace/MarketplaceNav.svelte';
    import Pusher from 'pusher-js';
    import toast from 'svelte-french-toast';
    import '../css/spinner.css';

    import { Button } from '$lib/components/ui/button';
    // props from marketplace controller
    export let authUser;
    export let userWhoAreNotReferred;
    export let pusherKey;
    export let pusherCluster;
    export let socketsDriver;
    export let soketiHost;
    export let soketiPort;
    export let pusherScheme;

    // referralClaim Context
    const referralClaimContext = {
        authUser: authUser,
        userWhoAreNotReferred: userWhoAreNotReferred,
    };
    setContext('referralClaimContext', referralClaimContext);

    onMount(() => {
        let params = {
            cluster: pusherCluster,
        };
        if (socketsDriver === 'soketi') {
            params = {
                wsHost: soketiHost,
                wsPort: soketiPort,
                forceTLS: pusherScheme ? true : false,
            };
        }

        var pusher = new Pusher(pusherKey, params);
        const channel = pusher.subscribe('bmn');
        pusher.logToConsole = true;

        channel.bind('bid-update', function (data) {
            const bidFor = data.bidData.bid_for;
            const bidData = data.bidData.bid;
            const bidValidity = bidData.bid_validity;
            const bidder = data.bidData.current_bidder;
            const nowTime = data.bidData.nowTime;
            const nextPriceLimit = data.bidData.next_minimum_price;

            // restart the timer
            initTimer(bidValidity, nowTime, bidFor.id);

            //update the price and the bidder
            document.getElementById(`current_bidder_name_${bidFor.id}`).innerHTML = bidder.name;
            document.getElementById(`current_bid_price_${bidFor.id}`).innerHTML = bidData.amount;
            document.getElementById(`min_price_${bidFor.id}`).innerHTML = nextPriceLimit;
            document.getElementById(`current_price_${bidFor.id}`).value = nextPriceLimit;

            const message = 'New update on bid ' + bidFor.name;
            toast.success(message);
        });

        pusher.connection.bind('error', function (err) {
            console.error('Pusher error:', err);
        });
    });
</script>

<svelte:component this="{MarketplaceNav}" />

<div class="col-12 min-vh-100 pt-4">
    <div class="px-3 pb-4">
        <h5 class="text-truncate mb-0 font-bold">Referral Claim Bid</h5>
    </div>
    <div class="filters mb-3">
        <Button variant="secondary" size="sm">Price</Button>
        <Button variant="outline" size="sm">Time</Button>
        <Button variant="outline" size="sm">Image Count</Button>
    </div>
    <!-- <svelte:component this={UserAuctionCard}/> -->
    <svelte:component this="{BidView}" />
</div>
